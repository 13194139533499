/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

const BodyContainer = styled.div`
  padding: 20px;
  max-width: 500px;
  margin: auto;
  display: block;
  font-family: Narkisim;
  color: #1d4999;
  box-shadow: 0 0 9px 0 rgba(216, 216, 216, 0.59);
  margin-bottom: 60px;
`;
export default ({ children }) => <BodyContainer>{children}</BodyContainer>;
