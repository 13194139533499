/** @jsx jsx */
import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group"; // ES6
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Fragment } from "react";

const Container = styled.div`
  padding: 40px;
  height: 00px;
  background-color: white;
  ul {
    list-style: none;
  }
  .list-item-appear {
    opacity: 0.01;
  }

  .list-item-appear.list-item-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  .list-item-enter {
    opacity: 0.01;
    transform: translate(0, 1em);
  }

  .list-item-enter.list-item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-out, transform 500ms ease-out;
    transform: translate(0, 0);
  }

  .list-item-leave {
    opacity: 1;
  }

  /* .list-item-leave.list-item-leave-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in, transform 500ms ease-in;
    transform: translate(-5em, 0) scale(0, 0);
  } */
`;

const Line = styled.div`
  border-bottom: 1px solid #9cb0d3;
  display: block;
  margin: auto;
  padding: 0px;
  width: 95%;
`;

const Header = styled.div`
  font-size: 1.4em;
  text-align: center;
  padding-bottom: 20px;
`;

const Donations = styled.div`
  margin: 20px;
  padding: 40px;
  padding-bottom: 25px;
  background-color: white;

  border: 1px solid #1d4999;
  font-size: 1.6em;
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-transform: capitalize;
`;

const SubText = styled.div`
  font-size: 20px;
  color: grey;
  grid-column: 1 / span 3;
  padding-top: 15px;
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      nextRecord: 1
    };
    this.onAddItem = this.onAddItem.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
  }

  onDeleteItem() {
    // make a copy of the list with slice()
    const list = this.state.list.slice();
    // update the copy
    list.shift();
    // set the state to the updated copy
    this.setState({
      list
    });
  }

  onAddItem(item) {
    // make a copy of the list with slice()
    const list = [...this.state.list, item];

    this.setState({
      list,
      nextRecord:
        this.state.nextRecord === this.props.data.donations.length - 1
          ? 1
          : this.state.nextRecord + 1
    });
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.props.data) {
        if (this.state.list.length === 5) this.onDeleteItem();
        this.onAddItem(this.props.data.donations[this.state.nextRecord]);
      }
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { data } = this.props;
    if (!data || !data.donations.length) {
      return <Fragment />;
    }
    return (
      <Container>
        <Header>Mikva Donors</Header>
        <ul>
          <ReactCSSTransitionGroup
            transitionName="list-item"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={true}
            transitionEnterTimeout={500}
            transitionLeave={true}
            transitionLeaveTimeout={300}
          >
            {this.state.list.map((item, index) => {
              return (
                <li key={index}>
                  <Donations>
                    <div> {item.amount}</div>
                    <div>{item.name.toLowerCase()}</div>
                    <SubText>{item.secondaryText}</SubText>
                  </Donations>
                </li>
              );
            })}
          </ReactCSSTransitionGroup>
        </ul>
      </Container>
    );
  }
}
