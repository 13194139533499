/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

const Contianer = styled.div`
  max-width: 1080px;
  margin: auto;
  margin-bottom: 0px;
`;
const AlertStyle = styled.div`
  /* margin: ; */

  width: 95%;
  padding: 20px;
  background-color: #cde1fc;
  box-shadow: 0 0 18px 0 rgba(216, 216, 216, 0.59);
  text-align: center;
`;

export const Alert = ({ data }) => <AlertStyle>{data.alert}</AlertStyle>;

export default ({ data }) => {
  return (
    <Contianer>
      {data.map((x, idx) => (
        <Alert key={idx} data={x} />
      ))}
    </Contianer>
  );
};
