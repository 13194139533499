import React, { Fragment, Component } from "react";
import _ from "lodash";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Zmanim from "./Partials/Zmanim/index";
import Background from "./Partials/Background";
import Announcements from "./Partials/Announcements";
import Donations from "./Partials/Rayzeit/donations";
import Pictures from "./Partials/Pictures";
import RightSide from "./Partials/RightSide";

const styles = {
  sectionsBoxShadow: `0 28px 56px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)`
};

const Container = styled.div`
  height: 2160px;
  width: 3840px;
  display: grid;
  font-family: Narkisim;
  color: #1d4999;
  font-size: 2em;
  grid-template-columns: auto auto auto;
`;

const BackgroundSection = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 1;
  z-index: -1;
`;

const ContentContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 1;
  margin: 60px;
  display: grid;
  grid-column-gap: 60px;
  width: 3720px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const MiddleSection = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 1;
  z-index: -1;
`;

const LeftSection = styled.div`
  grid-column-start: 1;
  grid-row: 1;
`;
const RightSection = styled.div`
  grid-column-start: 3;
  display: grid;
  margin: 0px;
  height: 2040px;
  grid-row: 1;
  grid-row-gap: 60px;
`;

const MidDivSection = styled.div`
  background-color: white;
  box-shadow: ${styles.sectionsBoxShadow};
  display: grid;
  grid-template-columns: auto 1240px;
`;

const ThermContianer = styled.div`
  padding: 50px;
  padding-bottom: 30px;
  background-color: white;
  box-shadow: ${styles.sectionsBoxShadow};
  display: grid;
  grid-template-rows: auto auto;
  height: 250px;
`;

const OuterTherm = styled.div`
  width: 1000px;
  height: 50px;
  border: solid 2px #1d4999;
  border-radius: 25px;
  justify-self: center;
`;
const InnerTherm = styled.div`
  width: ${props => props.width}px;
  height: 50px;
  background-color: #1d4999;
  border-radius: 25px;
`;
const ThermText = styled.div`
  padding: 10px;
  width: 1000px;
  justify-self: center;
  display: grid;
  grid-template-columns: auto auto;
`;

const Dollar = styled.div`
  font-size: 2em;
`;

const LeftAlign = styled.div`
  text-align: right;
`;
const OuterBoxShadow = styled.div`
  box-shadow: ${styles.sectionsBoxShadow};
`;
const Thermometer = ({ data }) => {
  if (!data || !data.thermometer) {
    return <Fragment />;
  }
  const formatedTotalRaised = data.thermometer.totalRaised
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return (
    <ThermContianer>
      <OuterTherm>
        <InnerTherm
          width={Number((1000 / 500000) * (data.thermometer.totalRaised || 0))}
        />
      </OuterTherm>
      <ThermText>
        <div>
          <Dollar>${formatedTotalRaised || "N/A"}</Dollar>
          Total Raised
        </div>
        <LeftAlign>
          <Dollar>$500,000</Dollar>
          Goal
        </LeftAlign>
      </ThermText>
    </ThermContianer>
  );
};

const LeftSectionBottom = styled.div`
  background-color: white;
  box-shadow: ${styles.sectionsBoxShadow};
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: undefined
    };
  }

  componentDidMount() {
    this.setData();
    this.interval = setInterval(() => this.setData(), 300000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setData() {
    fetch("https://iv4xo4sgab.execute-api.us-east-1.amazonaws.com/dev/get-data")
      .then(response => response.json())
      .then(data => this.setState({ data }));
  }

  render() {
    const { data } = this.state;
    return (
      <Container>
        <BackgroundSection>
          <Background />
        </BackgroundSection>

        <ContentContainer>
          <LeftSection>
            <OuterBoxShadow>
              <img
                src="https://res.cloudinary.com/duehlyib8/image/upload/c_scale,w_1200/v1582079066/brochure1a.jpg"
                alt=""
              />
            </OuterBoxShadow>
          </LeftSection>
          <MiddleSection>
            <OuterBoxShadow>
              <img
                src="https://res.cloudinary.com/duehlyib8/image/upload/c_scale,w_1200/v1582079278/brochure12.jpg"
                alt=""
              />
            </OuterBoxShadow>
          </MiddleSection>
          <RightSection>
            {/* <RightSide></RightSide>

            <Thermometer data={data}></Thermometer> */}
            <Zmanim data={data}></Zmanim>
            <Thermometer data={data}></Thermometer>
          </RightSection>
        </ContentContainer>
      </Container>
    );
  }
}
export default App;
