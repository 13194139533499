/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

const Line = styled.div`
  border-bottom: 1px solid #9cb0d3;
  display: block;
  margin: auto;
  padding: 10px;
  width: 95%;
`;
export default () => <Line />;
