/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

const BodyContainer = styled.div`
  padding: 40px;
  max-width: 1000px;
  margin: auto;
  display: block;
  box-shadow: 0 0 16px 0 rgba(216, 216, 216, 0.59);
  margin-bottom: 60px;
`;
export default ({ children }) => <BodyContainer>{children}</BodyContainer>;
