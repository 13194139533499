/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { Fragment } from "react";

const Container = styled.div`
  padding: 40px;
  background-color: white;
  text-align: center;
`;

const Line = styled.div`
  border-bottom: 1px solid #9cb0d3;
  display: block;
  margin: auto;
  padding: 0px;
  width: 95%;
`;

const Header = styled.div`
  font-size: 5.4em;
  text-align: center;
  color: red;
  padding-bottom: 60px;
  padding-top: 100px;

  animation: blink 5s linear infinite;
  @keyframes blink {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    41% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Announcement = styled.div`
  padding: 40px;
  font-size: 2em;
`;

export default ({ data }) => {
  return (
    <Container>
      <Header>This Motzei Shabbos!!</Header>
      <Announcement key={"1"}>
        Evening of Inspiration for women to benefit
      </Announcement>
      <Announcement
        css={css`
          font-size: 2.4em;
        `}
        key={"2"}
      >
        Mikvah Mei Chaya Pessel.
      </Announcement>
      <Announcement key={"3"}>Guest Speaker Mrs. Tehilla Gailer.</Announcement>
      <Announcement key={"3"}>9:00pm at 60 Seminole Dr.</Announcement>
    </Container>
  );
};
