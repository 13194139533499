import React, { Component } from "react";
import HomePage from "./components/HomePage";
import DigitalSignage from "./components/DigitalSignage";

const App = () => (
  <>
    {window.location.pathname === "/screen" ? <DigitalSignage /> : <HomePage />}
  </>
);
export default App;
