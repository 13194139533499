import React, { Fragment } from "react";
import Header from "./Partials/Header";
import Body from "./Partials/Body";
import Zmanim from "./Partials/Zmanim";
import Line from "./Partials/Line";
import Shiurim from "./Partials/Shiurim";
import Alerts, { alert } from "./Partials/Alerts";
import styled from "@emotion/styled";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 28px 56px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`;

export default ({ data }) => (
  <Container>
    <Header />
    {data ? (
      <Fragment>
        {/* {data.alerts.length ? <Alerts data={data.alerts} /> : ""} */}
        <Body>
          <Zmanim data={data.zmanim} alertData={data.alerts[0]} />
          <Line />
          <Shiurim data={data.shiurim} />
        </Body>
      </Fragment>
    ) : (
      ""
    )}
  </Container>
);
