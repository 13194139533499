/** @jsx jsx */
import { Component } from "react";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Fragment } from "react";

const Container = styled.div`
  padding: 40px;
  height: 900px;
`;

const Header = styled.div`
  font-size: 1.4em;
  text-align: center;
  padding-bottom: 20px;
`;

const Pictures = styled.div`
  max-height: 100%;
  padding: 30px;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  img {
    object-fit: scale-down;
    height: 800px;
    max-width: 100%;
    justify-self: center;
  }
`;

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgSrc: undefined,
      nextPictureIndex: 0
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.moveImage(), 4000);
  }

  moveImage() {
    if (this.props.data.pictures) {
      const { pictures } = this.props.data;
      this.setState({
        imgSrc: pictures[this.state.nextPictureIndex],
        nextPictureIndex:
          this.state.nextPictureIndex === pictures.length - 1
            ? 0
            : this.state.nextPictureIndex + 1
      });
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <Container>
        <Header>מעמד יציקת הבורות והנחת אבן הפינה</Header>
        <Pictures>
          <img src={this.state.imgSrc} alt="" />
        </Pictures>
      </Container>
    );
  }
}
