import React, { Fragment } from "react";
import { css } from "@emotion/core";

// export default () => (
//   <Fragment>
//     {/* <iframe
//       src={
//         "//cdn.bannersnack.com/banners/btiszf597/embed/index.html?userId=38881189&t=1551714186"
//       }
//       width="1370"
//       height="880"
//       scrolling="no"
//       frameBorder="0"
//       allowtransparency="true"
//       allow="autoplay"
//       allowfullscreen="true"
//     /> */}
//     {/* <iframe
//       src={
//         "//cdn.bannersnack.com/banners/bu5srm9ko/embed/index.html?userId=38881189&t=1552075660"
//       }
//       width="1920"
//       height="1080"
//       scrolling="no"
//       frameBorder="0"
//       allowTransparency="true"
//       allow="autoplay"
//       allowFullscreen="true"
//     /> */}
//     <img src="/images/ShulRendering.jpeg" alt="" />
//   </Fragment>
// );

import shulImage from "../../../../assets/images/ShulRendering.jpeg";

export default () => (
  <Fragment>
    <div
      css={css`
        width: 100%;
      `}
    >
      <img
        css={css`
          width: 100%;
        `}
        src="https://res.cloudinary.com/duehlyib8/image/upload/c_scale,w_3840/v1552078300/ShulRendering.jpg"
        alt=""
      />
    </div>
  </Fragment>
);
