/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Fragment } from "react";

const Container = styled.div`
  padding: 40px;
`;

const Line = styled.div`
  border-bottom: 1px solid #9cb0d3;
  display: block;
  margin: auto;
  padding: 0px;
  width: 95%;
`;

const Header = styled.div`
  font-size: 1.4em;
  text-align: center;
  padding-bottom: 20px;
`;

const Announcement = styled.div`
  padding: 40px;
`;

export default ({ data }) => {
  if (!data || !data.announcements.length) {
    return <Fragment />;
  }
  return (
    <Container>
      <Header>Announcements</Header>
      {data.announcements
        .filter(x => x.announcement)
        .map(x => (
          <Fragment>
            <Line />
            <Announcement key={x.announcement}>{x.announcement}</Announcement>
          </Fragment>
        ))}
    </Container>
  );
};
