import React, { Fragment, Component } from "react";
import Header from "./Partials/Header";
import Body from "./Partials/Body";
import Zmanim from "./Partials/Zmanim";
import Line from "./Partials/Line";
import Announcments from "./Partials/Announcments";

import Shiurim from "./Partials/Shiurim";
import Alerts from "./Partials/Alerts";
import styled from "@emotion/styled";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: undefined
    };
  }

  componentDidMount() {
    fetch("https://iv4xo4sgab.execute-api.us-east-1.amazonaws.com/dev/get-data")
      .then(response => response.json())
      .then(data => this.setState({ data }));
  }

  render() {
    const { data } = this.state;
    return (
      <Container>
        <Header />
        {data ? (
          <Fragment>
            {data.alerts.length ? <Alerts data={data.alerts} /> : ""}
            <Body>
              <Zmanim data={data.zmanim} />
              <Line />
              <Shiurim data={data.shiurim} />
              {data.announcements.length ? (
                <Fragment>
                  <Line />
                  <Announcments data={data.announcements} />
                </Fragment>
              ) : (
                ""
              )}
            </Body>
          </Fragment>
        ) : (
          ""
        )}
      </Container>
    );
  }
}
export default App;
