/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

const Contianer = styled.div`
  margin-top: 20px;
`;
const Header = styled.div`
  font-size: 1.4em;
`;

const ShiurStyle = styled.div`
  padding: 10px;
`;

const Day = ({ data }) => <ShiurStyle>{data.shiur}</ShiurStyle>;

export default ({ data }) => {
  return (
    <Contianer>
      <Header>Shiurim</Header>
      {data.map(x => (
        <Day key={x.shiur} data={x} />
      ))}
    </Contianer>
  );
};
