/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Alert } from "./Alerts";

const ZmanimContianer = styled.div`
  display: grid;
`;
const ZmanimHeader = styled.div`
  font-size: 1.4em;
`;

const DayStyle = styled.div`
  padding: 20px;
  display: grid;
  grid-row-gap: 14px;
`;

const DayHeader = styled.div`
  font-size: 1.2em;
`;
const Event = styled.div`
  padding-left: 20px;
`;
const EventItem = styled.span`
  padding-left: 10px;
`;

const Day = ({ dayData }) => (
  <DayStyle>
    <DayHeader>{dayData.day}</DayHeader>
    {dayData.events.map((event, idx) => (
      <Event key={idx}>
        {event.tefilah}:<EventItem>{event.time}</EventItem>
      </Event>
    ))}
  </DayStyle>
);

export default ({ data, alertData }) => {
  return (
    <ZmanimContianer>
      <Alert data={alertData} />
      {data.map(x => (
        <Day key={x.day} dayData={x} />
      ))}
    </ZmanimContianer>
  );
};
