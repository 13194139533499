/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import logo from "../../../../../assets/images/logo.png";

const Header = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  height: 160px;
  padding: 40px;
  border-bottom: 2px solid #29519d;
  font-family: Narkisim;
  color: #1d4999;
  text-align: center;
  .logo {
    max-height: 90%;
    margin: auto;
    display: block;
    padding-bottom: 10px;
  }
  .address {
  }
`;
export default () => (
  <Header>
    <img className="logo" src={logo} alt="" />
    <span>27 Pawnee Rd, Lakewood NJ 08701</span>
  </Header>
);
