/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

const Contianer = styled.div`
  margin-top: 20px;
`;
const Header = styled.div`
  font-size: 1.4em;
`;

const Announcement = styled.div`
  padding: 10px;
`;

export default ({ data }) => {
  return (
    <Contianer>
      <Header>Announcements</Header>
      {data.map(x => (
        <Announcement key={x.announcement}>{x.announcement}</Announcement>
      ))}
    </Contianer>
  );
};
