/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

const Contianer = styled.div`
  /* margin-top: 20px; */
  /* width: 500px; */
  max-width: 540px;
  margin: auto;
  /* height: 50px; */
  /* margin-top: 25px; */
  margin-bottom: 0px;

  font-family: Narkisim;
  color: #1d4999;
`;
const AnnouncementStyle = styled.div`
  margin: 15px 0;
  width: 500px;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #cde1fc;
  box-shadow: 0 0 9px 0 rgba(216, 216, 216, 0.59);
  font-size: 1.2em;
`;

const Announcement = ({ data }) => (
  <AnnouncementStyle>{data.alert}</AnnouncementStyle>
);

export default ({ data }) => {
  return (
    <Contianer>
      {data.map((x, idx) => (
        <Announcement key={idx} data={x} />
      ))}
    </Contianer>
  );
};
